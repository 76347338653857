import React from 'react'
import styled from 'styled-components'

const Body = styled.div`
  text-align: center;
  padding: 10vh 5vw;
  font-family: Helvetica, sans-serif;
  color: #333;
  font-size: 1.25rem;
`

const Heading = styled.h1`
  font-size: 3rem;
  padding-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`

const Article = styled.article`
  display: block;
  text-align: left;
  max-width: 650px;
  width: 100%;
  margin: 0 auto;

  div {
    p {
      padding-bottom: 1.5rem;
    }
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`

const Link = styled.a`
  color: #dc8100;
  text-decoration: none;

  &:hover {
    color: #333;
    text-decoration: none;
  }
`

const MaintenancePage = () => {
  return (
    <Body>
      <Article>
        <Heading>We&rsquo;ll be back soon!</Heading>
        <div>
          <p>
            Sorry for the inconvenience but we&rsquo;re performing some
            maintenance at the moment. If you need to, you can always{' '}
            <Link href="mailto: careers_support@hopla.online">contact us</Link>, otherwise
            we&rsquo;ll be back online shortly!
          </p>
          <p>&mdash; The Team</p>
        </div>
      </Article>
    </Body>
  )
}

export default MaintenancePage