import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'

import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { PagesProvider } from './context/FormPagesContext'
import { ModalProvider } from './context/ModalContext'
import { WorkEthicsProvider } from './context/WorkEthics'

// pages
import Login from './pages/Login'
import Register from './pages/Register'
import ForgetPassword from './pages/ForgetPassword'
import ResetPassword from './pages/ResetPassword'
import PasswordVerification from './pages/PasswordVerification'
import PasswordSuccessful from './pages/PasswordSuccessful'
import VerifySuccessful from './pages/VerifySuccessful'
import Verify from './pages/Verify'
import ScreenOne from './pages/onboarding/ScreenOne'
import MainOnboard from './pages/onboarding'
import Settings from './pages/settings'
import RemoteHome from './pages/remoteSetup/RemoteHome'
import RemoteSetup from './pages/remoteSetup'
import WelcomeScreen from './pages/welcome'
import WorkEthicsHome from './pages/WorkEthics/WorkEthicsHome'
import WorkEthicsStart from './pages/WorkEthics'
import Contact from './pages/contact'
import UpdatePassword from './pages/UpdatePassword'
import Home from './pages/home/index'
import WorkEthicsSuccess from './pages/WorkEthics/WorkEthicsSuccess'
import ProfileIndex from './pages/profile/index'
import Job from './pages/jobs'
import Maintenance from './pages/Maintenance.jsx'

import CrispChat from './components/CrispChat'
import SubscribeToMailerLiteModal from './components/SubscribeToMailerLiteModal'

import AuthLayouts from './layouts/AuthLayouts'

import ReactGA4 from './lib/ReactGA4'

import ProtectedRoute from './helpers/ProtectedRoute'

import { theme } from './utils/theme'

import { store } from './redux/store'

import { MAINTENANCE_MODE } from './constants/constant.js'

import { getFromLocalStorage } from './utils/util'

const reactGA4 = new ReactGA4()
reactGA4.initialize()

const token = getFromLocalStorage('token')

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <ModalProvider>
          <WorkEthicsProvider>
            <PagesProvider>
              <Router>
                <CrispChat />
                {token && <SubscribeToMailerLiteModal />}
                <Switch>
                  <Route exact path="/home" component={Home} />

                  {MAINTENANCE_MODE === 'true' ? (
                    <>
                      <Route
                        exact
                        path="/maintenance"
                        component={Maintenance}
                      />
                      <Redirect to="/maintenance" replace />
                    </>
                  ) : (
                    <Redirect from="/maintenance" to="/home" />
                  )}

                  <ProtectedRoute
                    exact
                    path="/welcome"
                    component={WelcomeScreen}
                  />
                  <Route path="/jobs/:token" component={Job} />
                  <ProtectedRoute
                    exact
                    path="/remote-fitness-level/success"
                    component={WorkEthicsSuccess}
                  />
                  <ProtectedRoute
                    exact
                    path="/remote-fitness-level/create"
                    component={WorkEthicsStart}
                  />
                  <ProtectedRoute
                    exact
                    path="/remote-fitness-level/update"
                    component={WorkEthicsStart}
                  />
                  <ProtectedRoute
                    exact
                    path="/remote-fitness-level"
                    component={WorkEthicsHome}
                  />
                  <ProtectedRoute
                    exact
                    path="/remote-setup/create"
                    component={RemoteSetup}
                  />
                  <ProtectedRoute
                    exact
                    path="/remote-setup/update"
                    component={RemoteSetup}
                  />
                  <ProtectedRoute
                    exact
                    path="/remote-setup"
                    component={RemoteHome}
                  />
                  <ProtectedRoute
                    exact
                    path="/cv-review"
                    component={MainOnboard}
                  />
                  <ProtectedRoute exact path="/settings" component={Settings} />
                  <ProtectedRoute
                    exact
                    path="/onboarding"
                    component={ScreenOne}
                  />
                  <ProtectedRoute
                    exact
                    path="/profile"
                    component={ProfileIndex}
                  />
                  <Route exact path="/" component={Home} />
                  <Route path="/reset-password/:token">
                    <ResetPassword />
                  </Route>
                  {/* update password route */}
                  <Route path="/update-password/:token">
                    <UpdatePassword />
                  </Route>
                  <Route exact path="/forget-password/success">
                    <PasswordSuccessful />
                  </Route>
                  <Route path="/verification/:token">
                    <VerifySuccessful />
                  </Route>
                  <Route exact path="/verification">
                    <Verify />
                  </Route>
                  <Route exact path="/forget-password/verify">
                    <PasswordVerification />
                  </Route>
                  <Route exact path="/forget-password">
                    <ForgetPassword />
                  </Route>
                  <Route path="/contact">
                    <Contact />
                  </Route>
                  <Route path="/signup">
                    <Register />
                  </Route>
                  <Route path="/login">
                    <AuthLayouts>
                      <Login />
                    </AuthLayouts>
                  </Route>
                </Switch>
              </Router>
            </PagesProvider>
          </WorkEthicsProvider>
        </ModalProvider>
      </Provider>
    </ThemeProvider>
  )
}

export default App
