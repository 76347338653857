import React, { useMemo, useContext, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import styled from 'styled-components'
import { FiCheckCircle, FiClock } from 'react-icons/fi'
import { SlQuestion } from 'react-icons/sl'
import { BsCheck2Circle, BsBookmarkFill, BsBookmark } from 'react-icons/bs'
import { IoClose } from 'react-icons/io5'
import { MdOutlineDateRange, MdOutlineSupervisorAccount } from 'react-icons/md'
import pluralize from 'pluralize'
import toast, { Toaster } from 'react-hot-toast'
import { sanitize } from 'dompurify'

import { ModalContext } from '../../../../src/context/ModalContext'
import { PagesContext } from '../../../../src/context/FormPagesContext'

import { updateActiveJob } from '../../../redux/feature/jobSlice'

import Modal from '../../../../src/components/Modal'
import Tag from './Tag'
import SubscribeToMailerLiteModal from '../../../components/SubscribeToMailerLiteModal'

import Login from '../../Login'

import apiClient from '../../../helpers/apiClient'

import {
  capitalize,
  numberCommaSeparator,
  getFromLocalStorage,
} from '../../../utils/util'

const JobDetails = ({ job }) => {
  const dispatch = useDispatch()

  const [confirmationModal, setConfirmationModal] = useState(true)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [loading, setLoading] = useState(false)
  const [hoverBookMarkIcon, setHoverBookmarkIcon] = useState(
    <BsBookmark className="icon" />
  )
  const [successMessage, setSuccessMessage] = useState('')

  const [channels, setChannels] = useState([])
  const [channelId, setChannelId] = useState(0)

  const { userDetails } = useContext(PagesContext)
  const { setModal } = useContext(ModalContext)

  const applicantData = getFromLocalStorage('applicant-data')
  const cvDetails = getFromLocalStorage('applicant-cv-data')
  const userId = getFromLocalStorage('user_id')
  const token = getFromLocalStorage('token')
  const isSubscribed = Boolean(
    getFromLocalStorage('applicant-data')?.is_subscribed
  )

  const updatedBenefit = useMemo(() => {
    if (job?.benefits) {
      return JSON.parse(job?.benefits)
    }
    return []
  }, [job])

  const technicalSkills = useMemo(() => {
    if (job?.technical_skills) {
      return JSON.parse(job?.technical_skills)
    }
    return []
  }, [job])

  const experience = useMemo(() => {
    if (job?.experience) {
      return JSON.parse(job?.experience)
    }
    return []
  }, [job])

  const languages = useMemo(() => {
    if (job?.language) {
      const newLangs = JSON.parse(job?.language)
      const langs = newLangs.map((lang) => lang.value)
      return langs.join(',')
    }
    return ''
  }, [job])

  const handleApplyJob = () => {
    setModal(true)
    if (token) {
      setIsLoggedIn(true)
    }
  }

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage)
      setSuccessMessage('')
    }
  }, [successMessage])

  useEffect(() => {
    const updatedJob = { ...job }
    if (userId) {
      const request = {
        applicant_id: cvDetails?.id?.toString(),
        job_id: job?.id?.toString(),
        is_saved: true,
      }
      const urlParams = new URLSearchParams(request).toString()

      apiClient
        .get(`/saved-jobs?${urlParams}`)
        .then((response) => {
          const newUpdatedJob = { ...updatedJob }
          if (response?.data?.data?.length > 0) {
            newUpdatedJob.is_saved = true
          } else {
            newUpdatedJob.is_saved = false
          }
          dispatch(updateActiveJob(newUpdatedJob))
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message)
        })
    }
    dispatch(updateActiveJob(updatedJob))
  }, [])

  const handleConfirmApply = async () => {
    if (channelId === 0 || channelId === null) {
      toast.error('Please specify where did you learn about us')
      return
    }
    setLoading(true)

    const reqBody = {
      applicant_id: cvDetails?.id?.toString(),
      applicant_name: `${userDetails?.first_name} ${userDetails?.last_name}`,
      email: applicantData?.email,
      added_by: 'Recruiter',
      phone_number: cvDetails?.contact?.toString() || '',
      job_id: job?.id?.toString(),
      job_title: job?.title,
      tags: 'Pending',
      channel_id: channelId,
    }

    apiClient
      .post(`screening/${cvDetails?.id?.toString()}/add-applicant`, reqBody)
      .then(() => {
        setConfirmationModal(false)
        setLoading(false)
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message)
        setLoading(false)
      })
  }

  function handleClose() {
    dispatch(updateActiveJob(null))
  }

  const handleSuccessfulApply = () => {
    setModal(false)
    setConfirmationModal(true)
  }

  const handleCloseSuccessfulApply = () => {
    setConfirmationModal(true)
  }

  const handleSaveJob = () => {
    if (localStorage.getItem('token') && cvDetails.id) {
      setIsLoggedIn(true)
      const reqBody = {
        applicant_id: cvDetails?.id?.toString(),
        job_id: job?.id?.toString(),
      }

      apiClient
        .post(`job/saved`, reqBody)
        .then((response) => {
          const updatedJob = { ...job }
          updatedJob.is_saved = response?.data?.resp?.savedJob?.is_saved
          setSuccessMessage(response?.data?.resp?.successMessage)
          dispatch(updateActiveJob(updatedJob))
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message)
          setLoading(false)
        })
    } else {
      setModal(true)
    }
  }

  useEffect(() => {
    if (localStorage.getItem('token') && cvDetails.id) {
      apiClient
        .get(`/channels`)
        .then((response) => {
          setIsLoggedIn(true)
          setChannels(response.data.data.data)
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message)
        })
    }
  }, [])

  const handleSelectChannel = (e) => {
    const value = e.target.value
    const idx = channels.findIndex((x) => x.channel === value)
    if (idx > -1) {
      const channelId = channels[idx].id
      setChannelId(channelId)
    } else {
      setChannelId(0)
    }
  }

  let modalPopup = (
    <Modal showPrimaryBtn={false} noCancel>
      <Login type="modal" />
    </Modal>
  )

  if (isLoggedIn && !isSubscribed) modalPopup = <SubscribeToMailerLiteModal />

  return (
    <StyledJobDetails>
      {modalPopup}
      {isLoggedIn && confirmationModal && (
        <Modal
          btnTitle={loading ? 'Loading...' : 'Continue'}
          btnFunc={handleConfirmApply}
        >
          <ApplyNow>
            <SlQuestion className="question_icon" />
            <h1>Confirm Application</h1>
            <p>Clicking the Continue button will confirm your application.</p>
            <br />
            <br />
            <div className="inputs">
              <label htmlFor="channel">Where did you learn about us?</label>
              <select
                className="select"
                name="channel"
                id="id"
                onChange={(e) => handleSelectChannel(e)}
              >
                <option>Choose Option</option>
                {channels.map((ch) => (
                  <option key={ch.id} value={ch.channel}>
                    {ch.channel}
                  </option>
                ))}
              </select>
            </div>
          </ApplyNow>
        </Modal>
      )}
      {isLoggedIn && !confirmationModal && (
        <Modal
          btnTitle="Finish"
          btnFunc={handleSuccessfulApply}
          closeBtnFunc={handleCloseSuccessfulApply}
          noCancel
        >
          <SuccessfulApply>
            <BsCheck2Circle className="success_icon" />
            <h1>Application Successful!</h1>
            <p>
              Thank you for applying! You will receive a response within 2
              business days.
            </p>
          </SuccessfulApply>
        </Modal>
      )}

      <div className="close-wrapper">
        <IoClose onClick={handleClose} className="close-icon" />
      </div>
      <div className="title_section">
        <h2 className="title">{job?.title}</h2>
      </div>
      <p className="salary">
        PHP {numberCommaSeparator(job.min_salary)} - PHP{' '}
        {numberCommaSeparator(job.max_salary)}
      </p>
      <div className="more_details">
        <div className="details">
          <MdOutlineSupervisorAccount className="icon" />
          <p className="content">
            Hiring {job?.active_vacancy}{' '}
            {pluralize('Candidate', job?.active_vacancy)}
          </p>
        </div>
        <div className="details">
          <MdOutlineDateRange className="icon" />
          <p className="content">{capitalize(job?.job_type)}</p>
        </div>
        <div className="details">
          <FiClock className="icon" />
          <p className="content">{job?.time_shift}</p>
        </div>
      </div>
      {job?.skills_match && (
        <div className="matched_skills_highlight">
          <FiCheckCircle className="icon" />
          <p className="content">Your skills match this job</p>
        </div>
      )}

      <div className="button__group">
        <button onClick={handleApplyJob}>Apply Now</button>
        {job?.is_saved ? (
          <div className="action active" onClick={handleSaveJob}>
            <BsBookmarkFill className="icon" />
            <p>Saved</p>
          </div>
        ) : (
          <div
            className="action"
            onClick={handleSaveJob}
            onMouseEnter={() => {
              setHoverBookmarkIcon(<BsBookmarkFill className="icon" />)
            }}
            onMouseLeave={() => {
              setHoverBookmarkIcon(<BsBookmark className="icon" />)
            }}
          >
            {hoverBookMarkIcon}
            <p>Save</p>
          </div>
        )}
      </div>

      <div className="section">
        <div className="section_title">Benefits</div>
        <div className="section_flex">
          {updatedBenefit?.map((benefit) => (
            <Tag key={benefit} text={benefit} />
          ))}
        </div>
      </div>

      <div className="section">
        <div className="section_title">Required Technical Skills</div>
        <div className="section_flex">
          {technicalSkills?.map((skill) => (
            <Tag key={skill.value} text={skill.value} />
          ))}
        </div>
      </div>

      <p
        className="desc"
        dangerouslySetInnerHTML={{ __html: sanitize(job?.description) }}
      />

      <div className="section_grid">
        <div className="section">
          <div className="section_title">Experience</div>
          <div className="section_content">{`${experience[0].specification}: ${experience[0].years}`}</div>
        </div>
        {job.education && (
          <div className="section">
            <div className="section_title">Education</div>
            <div className="section_content">{job?.education}</div>
          </div>
        )}
      </div>
      {languages !== '' && (
        <div className="section">
          <div className="section_title">Language</div>
          <div className="section_content">{languages}</div>
        </div>
      )}
      <Toaster position="top-right" reverseOrder={false} />
    </StyledJobDetails>
  )
}

export default JobDetails

const StyledJobDetails = styled.div`
  width: 100%;
  padding: 1.875rem 2rem;
  border: 1px solid #dfe2ec;
  border-radius: 3px;
  height: 81.5%;
  overflow-y: auto;

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    overflow-y: scroll;
    height: calc(100vh - 30px);
    padding: 120px 1.5rem;
    z-index: 101;
  }

  .close-wrapper {
    display: none;

    @media (max-width: 768px) {
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      justify-content: flex-end;
      padding: 1.8rem 1.5rem;
      background: #fff;
      width: 100%;
      border-bottom: 1px solid #e4e4e4;

      .close-icon {
        font-size: 1.5rem;
        cursor: pointer;
      }
    }
  }

  .title_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 5px;

    .title {
      font-size: 1.5rem;
      font-weight: bold;
      color: #31374f;
      max-width: 70%;

      @media (max-width: 768px) {
        margin-bottom: 1rem;
      }
    }
  }

  .button__group {
    display: flex;
    align-items: center;
    gap: 0.875rem;
    margin-top: 0.5rem;

    @media (max-width: 768px) {
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      background: #fff;
      display: flex;
      justify-content: flex-end;
      padding: 1.85rem 1.5rem;
      box-shadow: 0px -5px 10px rgba(199, 199, 199, 0.25);
    }

    button {
      border: none;
      outline: none;
      background: #ff5880;
      color: #fff;
      padding: 0.75rem 1.5rem;
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      height: 2.8rem;
      max-width: 130px;
    }

    .action {
      display: flex;
      align-items: center;
      width: 100px;
      height: 2.8rem;
      background-color: #fff;
      border: 1px solid #31374f;
      color: #31374f;
      cursor: pointer;
      gap: 0.3rem;
      padding-left: 0.5rem;

      .icon {
        width: 24px;
        height: 24px;
      }

      &:hover,
      &.active {
        background: #edf1ff;
        color: #31374f;
        font-weight: 500;
      }
    }
  }

  .salary {
    font-size: 1rem;
    font-weight: 500;
    color: #31374f;
  }

  .more_details {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 0.75rem;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .details {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .icon {
        font-size: 1.25rem;
        color: #31374f;
      }

      .content {
        font-size: 0.875rem;
        font-weight: 500;
        color: #31374f;
      }
    }
  }

  .section_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
    max-width: 450px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .section {
    margin-top: 1.25rem;

    .section_title {
      font-size: 1rem;
      font-weight: bold;
      color: #31374f;
      margin-bottom: 0.5rem;
    }

    .section_flex {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.5rem;
    }

    .section_content {
      font-size: 0.875rem;
      font-weight: 500;
    }
  }

  .desc {
    margin-top: 1.25rem;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    color: #31374f;
    line-height: 1.5;
  }

  .matched_skills_highlight {
    display: flex;
    font-size: 0.75rem;
    font-weight: 400;
    gap: 0.5rem;
    color: #28a745;
    margin: 0.75rem 0;

    .icon {
      font-size: 1rem;
      color: #28a745;
    }
  }
`

const ApplyNow = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  align-items: center;
  justify-content: center;

  .question_icon {
    font-size: 5rem;
    color: #ffa500;
    margin: 0.5rem;
  }

  h1,
  p {
    text-align: center;
  }

  .select,
  input {
    display: block;
    margin-top: 0.7rem;
    width: 100%;
    height: 2.5rem;
    border-radius: 3px;
    border: 1px solid #aeb3c9;
    padding: 0 1rem;
    outline: none;
    color: #858aa0;
    cursor: pointer;

    &.unique {
      border: none;
      margin-top: 0;
      height: 100%;
    }

    &::placeholder {
      color: #858aa0;
      font-size: 0.75rem;
    }
  }
`
const SuccessfulApply = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .success_icon {
    font-size: 5rem;
    color: #5cca95;
    margin: 0.5rem;
  }

  h1,
  p {
    text-align: center;
  }
`
